import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const CanISendAttachmentsViaDm = ({ location }) => {
  const title = "Can I attach an image to the Instant Win results notification?"
  const list = [
    { title: "Frequently Asked Questions", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="Twitter Instant Win campaign to attach and distribute images, videos, excel or other files as part of the giveaway. (Some customization is required)."
          pagepath={location.pathname}
        />
        <Breadcrumb title="Frequently Asked Questions" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              Can I send attachments in the direct message of the winning result
              notification?
            </h1>
            <p className="qa-page__answer">
              Attachments cannot be specified in direct messages sent
              automatically by the PARKLoT system.
            </p>
            <p className="qa-page__text">
              Customization is required to send attachments such as images and
              videos using PARKLoT functions.
            </p>
            <p className="qa-page__text">
              If you have data or materials that you want to send to
              participants, we recommend using a storage service such as Google
              Drive or other storage services.
            </p>
            <p className="qa-page__text">
              Try to get a shareable link and send that link as a message.
            </p>
            <div>
              <h3 className="qa-page__h3">
                Try to get a shareable link and send that link as a message.
              </h3>
              <p className="qa-page__text">
                Yes, the wording of the direct messages sent to campaign
                participants to notify them of the results can be changed from
                the administration screen.
              </p>
              <p className="qa-page__text">
                You can also send URL links via direct message.
              </p>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default CanISendAttachmentsViaDm
